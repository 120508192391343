const content = {
  en: {
    locale: {
      header: {
        nav: {
          home: 'Home',
          about: 'About',
          skills: 'Skills',
          works: 'Works',
          contact: 'Contact',
        },
        resume: {
          name: 'Resume',
          downloadName: 'tarsh-resume',
          downloadMessage: 'Downloading resume',
        },
      },
      homeSection: {
        intro: "Hello, I'm Tarsh Swarnkar.",
        welcome: 'Welcome to my portfolio',
        smallDescription: "I'm a",
        animatedTexts: {
          textOne: 'Full-Stack Developer',
          textTwo: 'React Developer',
          textThree: 'CSS Wizard',
          textFour: 'JavaScript Ninja',
          textFive: 'AI enthusiast',
          textSix: 'UI/UX Designer',
        },
        description: "I'm a self-taught and motivated full-stack developer based in India.",
        buttons: {
          work: 'View Works',
          hire: 'Contact',
        },
      },
      aboutSection: {
        sectionName: 'About Me',
        description:
          'As a skilled and enthusiastic developer, I specialize in creating user-friendly, responsive websites with exceptional functionality. My proficiency lies in coding efficient, engaging interfaces using advanced tools and techniques. I thrive on tackling challenges and transforming them into opportunities to innovate and discover creative solutions.',
      },
      skillsSection: {
        sectionName: 'Tech Stack',
        showMore: 'Show More',
        showLess: 'Show Less',
      },
      worksSection: {
        sectionName: 'Works',
        intro:
          'Amazing Full-Stack Web Development Experiences: Merging Design and Functionality. Here are some examples of my work.',
        workElement: {
          tools: 'Tools',
          buttons: {
            demo: 'Live Demo',
            code: 'View Code',
          },
          featured: 'Featured Project',
        },
        // noir: {
        //   name: 'Fit India Club Website',
        //   description:
        //     'FIC is a website for the Fit India Club of the college, which is updated on a regular basis and improved to enhance user experience and provide relevant information.',
        //   alt: 'FIC website',
        // },
        noir: {
          name: 'SahYog Website',
          description:
            'SahYog is a Next.js platform that allows individual users to receive financial support directly from their community.',
          alt: 'Sahyog website',
        },
        // lasso: {
        //   name: 'ScholarSangh',
        //   description:
        //     'A scholarship portal saves users time by consolidating scholarship searches into one platform, potentially saving hours of effort.',
        //   alt: 'ScholarSangh website',
        // },
        lasso: {
          name: 'PassMan',
          description:
            'PassMan allows users to manage their passwords. Users can add new passwords, view existing passwords, update them if needed, and delete them when necessary.',
          alt: 'Passman website',
        },
        casaECor: {
          name: 'ScholarSangh',
          description:
            'A scholarship portal saves users time by consolidating scholarship searches into one platform, potentially saving hours of effort.',
          alt: 'ScholarSangh website',
        },
      },



      
      contactSection: {
        sectionName: 'Contact',
        intro:
          'To contact me, fill out the form below, or if you prefer, contact me via LinkedIn or send an email.',
        placeholders: {
          placeholderName: 'Name',
          placeholderMessage: 'Message',
        },
        button: 'Send',
      },
      toast: {
        contact: {
          success: 'Message sent successfully!',
          error: 'Error! Please try again later.',
        },
      },
      footer: 'source',
    },
  },
};

export { content };
