import WorkElement from './WorkElement';
import FicMobileImg from '../assets/images/works/fic-mobile.jpg';
import FicDesktopImg from '../assets/images/works/fic-desktop.png';
import ScholarMobileImg from '../assets/images/works/scholarsangh-mobile.jpg';
import ScholarDesktopImg from '../assets/images/works/scholarsangh-desktop.png';
import BadmintonMobileImg from '../assets/images/works/badminton-tutorials-mobile.jpg';
import BadmintonDesktopImg from '../assets/images/works/badminton-tutorials-desktop.png';
// import AlugaFacilMobileImg from '../assets/images/works/aluga-facil.jpg';
// import AlugaFacilDesktopImg from '../assets/images/works/aluga-facil-desktop.jpg';
import SahyogMobileImg from '../assets/images/works/sahyog-mobile.jpg';
import SahyogDesktopImg from '../assets/images/works/sahyog-desktop.jpeg';
import PassmanMobileImg from '../assets/images/works/passman-mobile.jpg';
import PassmanDesktopImg from '../assets/images/works/passman-desktop.png';

import Slider from 'react-slick';
import { i18n } from '../locale/i18n';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Works = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <section name='works' className='pt-12 md:pt-20 w-full text-theme-white'>
      <div className='flex flex-col w-full h-full max-w-[900px] mx-auto py-4 px-8'>
        <div className='pb-10'>
          <h1 className='font-medium text-xl 2xl:text-2xl border-b-4 inline-block border-theme-white mb-4 pb-1'>
            {i18n.t('worksSection.sectionName')}
          </h1>
          <p className='text-base 2xl:text-lg'>{i18n.t('worksSection.intro')}</p>
        </div>

        <>
          {/* Mobile Slider */}
          <div className='mx-auto w-full max-w-[400px] md:hidden'>
            <Slider {...settings}>
              <div>
                <WorkElement
                  img={SahyogMobileImg}
                  alt={i18n.t('worksSection.noir.alt')}
                  name={`${i18n.t('worksSection.noir.name')}`}
                  description={`${i18n.t('worksSection.noir.description')}`}
                  tools='Next JS, Tailwind CSS, MongoDB'
                  link='https://sahyog-connect.vercel.app/'
                  code='https://github.com/T1A0R3S2H/SahYog'
                />
              </div>



              <div>
                <WorkElement
                  img={PassmanMobileImg}
                  alt={i18n.t('worksSection.lasso.alt')}
                  name={`${i18n.t('worksSection.lasso.name')}`}
                  description={`${i18n.t('worksSection.lasso.description')}`}
                  tools='React JS, Tailwind CSS, Node JS, Express JS, MongoDB'
                  link='https://pass-man-mern.netlify.app/'
                  code='https://github.com/T1A0R3S2H/Password-Manager-MERN'
                />
              </div>

              <div>
                <WorkElement
                  img={ScholarMobileImg}
                  alt={i18n.t('worksSection.casaECor.alt')}
                  name={`${i18n.t('worksSection.casaECor.name')}`}
                  description={`${i18n.t('worksSection.casaECor.description')}`}
                  tools='HTML5, CSS3, JavaScript'
                  link='https://scholar-sangh.vercel.app/'
                  code='https://github.com/T1A0R3S2H/GSC-24_ScholarSangh'
                />
              </div>

              
              {/* <div>
                <WorkElement
                  img={AlugaFacilMobileImg}
                  alt={i18n.t('worksSection.alugaFacil.alt')}
                  name={`${i18n.t('worksSection.alugaFacil.name')}`}
                  description={`${i18n.t('worksSection.alugaFacil.description')}`}
                  tools='React, TypeScript, Sass'
                  link='https://aluga-facil-ten.netlify.app/'
                  code='https://github.com/tiagocreator/aluga-facil'
                />
              </div> */}
            </Slider>
          </div>
          {/* Desktop */}
          <div className='hidden md:flex flex-col'>
            <WorkElement
              img={SahyogDesktopImg}
              alt={i18n.t('worksSection.noir.alt')}
              name={`${i18n.t('worksSection.noir.name')}`}
              description={`${i18n.t('worksSection.noir.description')}`}
              tools='Next JS, Tailwind CSS, MongoDB'
              link='https://sahyog-connect.vercel.app/'
              code='https://github.com/T1A0R3S2H/SahYog'
            />

            <WorkElement
              img={PassmanDesktopImg}
              alt={i18n.t('worksSection.lasso.alt')}
              name={`${i18n.t('worksSection.lasso.name')}`}
              description={`${i18n.t('worksSection.lasso.description')}`}
              tools='React JS, Tailwind CSS, Node JS, Express JS, MongoDB'
              link='https://pass-man-mern.netlify.app/'
              code='https://github.com/T1A0R3S2H/Password-Manager-MERN'
              reverse
            />

            <WorkElement
              img={ScholarDesktopImg}
              alt={i18n.t('worksSection.casaECor.alt')}
              name={`${i18n.t('worksSection.casaECor.name')}`}
              description={`${i18n.t('worksSection.casaECor.description')}`}
              tools='HTML5, CSS3, JavaScript'
              link='https://scholar-sangh.vercel.app/'
              code='https://github.com/T1A0R3S2H/GSC-24_ScholarSangh'
            />
            {/* <WorkElement
              img={AlugaFacilDesktopImg}
              alt={i18n.t('worksSection.alugaFacil.alt')}
              name={`${i18n.t('worksSection.alugaFacil.name')}`}
              description={`${i18n.t('worksSection.alugaFacil.description')}`}
              tools='React, TypeScript, Sass'
              link='https://aluga-facil-ten.netlify.app/'
              code='https://github.com/tiagocreator/aluga-facil'
              reverse
            /> */}
          </div>
        </>
      </div>
    </section>
  );
};

export default Works;
